import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import FormItem from './comandaItemForm';
import FormPagamento from './comandaPagamentoForm';

import {
	setModoTela, initForm, salvar,
	setModoTelaItem, initFormItem, salvarItem, excluirItem, getListaItens,
	setModoTelaPagamento, initFormPagamento, salvarPagamento, excluirPagamento, getListaPagamentos
} from './comandaActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

class ComandaForm extends Component {

	state = {

	}

    componentWillMount() {
		this.props.getListaItens(this.props.registro.id);
		this.props.getListaPagamentos(this.props.registro.id)
    }

    render() {

		let readOnly = this.props.excluir || this.props.formularioValues.datahora_fechamento ? 'readOnly' : '';

		let totalItem = 0;
		let totalPagamento = 0;

		(this.props.listaItens || []).forEach(item => {											
			totalItem += parseFloat(item.valor);
			totalPagamento += item.pago ? parseFloat(item.valor) : 0;
		});

		(this.props.listaPagamentos || []).forEach(item => {											
			totalPagamento += parseFloat(item.valor);
		});

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Select
								name='id_usuario'
								label='Cliente' placeholder='Informe o cliente'
								cols='12 12 12 12'
								readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly}
								options={this.props.listaUsuario}
								value={this.props.formularioValues.id_usuario}
								onChange={data => {
									this.props.initForm({
										...this.props.formularioValues,
										id_usuario: data,
										telefone: this.props.listaUsuario.filter(item => item.id == data).map(item => item.telefone).join('')
									});
									
								}} />
							
							{!this.props.formularioValues.id_usuario &&
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe a descrição'
								cols='12 12 12 12'
								readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly} />}
								
							<Field
								name='telefone'
								component={LabelAndInputMask}
								label='Celular' placeholder='Informe a telefone'
								cols='12 6 4 3'
								mask='(99) 9 9999-9999'
								readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly} />
						</Row>

						</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (!this.props.formularioValues.id ? (
									<Button
										text={!this.props.formularioValues.id ? 'Abrir Comanda' : 'Salvar'}
										submit
										type={'success'}
										icon={'fa fa-check'} />
								) : (
									<Button
										text={!this.props.formularioValues.datahora_fechamento ? 'Finalizar' : 'Reabrir'}
										type={'success'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.salvar({
											...this.props.formularioValues,
											datahora_fechamento: this.props.formularioValues.datahora_fechamento ? null : DateFormat.getDataHoraAtual()
										})} />
								))}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
				{this.props.formularioValues.id ? (
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 6 6'>
								{(this.props.modoTelaItem == 'lista' && !readOnly) && 
								<Row>
									<Grid cols='12 6 4 4'>
										<Button
											text='Adicionar Item'
											type={'success'}
											icon={'fa fa-plus'}
											event={() => {
												this.props.setModoTelaItem('cadastro', {});
												this.props.initFormItem({
													id_comanda: this.props.formularioValues.id
												});
											}} />
									</Grid>
								</Row>}

								{this.props.modoTelaItem == 'cadastro' ? (
									<FormItem onSubmit={this.props.salvarItem} />
								) : (
									<Table responsive>
										<THead>
											<Tr>
												<Th>Produto</Th>
												<Th alignCenter>Pago</Th>
												<Th alignCenter>Qtd</Th>
												<Th alignRight>Valor</Th>
												<Th></Th>
											</Tr>
										</THead>
										<TBody>
											{(this.props.listaItens || []).map(item => {									
												return (
													<Tr key={item.id}>
														<Td>{item.nome_produto}</Td>
														<Td alignCenter>{item.pago ? 'Pago' : ''}</Td>
														<Td alignCenter>{FormatUtils.formatarValorTela(item.quantidade, 0)}</Td>
														<Td alignRight>{FormatUtils.formatarValorTela(item.valor, 2)}</Td>												
														<Td alignRight minWidth={50}>
															<ButtonTable
																type={'danger'}
																icon={'fas fa-trash-alt'}
																visible={!readOnly}
																event={() => {
																	this.props.excluirItem(item);
																}} />
														</Td>
													</Tr>
												);
											})}
											<Tr>
												<Th style={{ fontSize: 16 }}>Total</Th>
												<Th></Th>
												<Th></Th>
												<Th alignRight style={{ fontSize: 16 }}>{FormatUtils.formatarValorTela(totalItem, 2)}</Th>
												<Th></Th>
											</Tr>
										</TBody>
									</Table>
								)}
							</Grid>

							<Grid cols='12 12 6 6'>
								{(this.props.modoTelaPagamento == 'lista' && !readOnly) &&
								<Row>
									<Grid cols='12 6 4 4'>
										<Button
											text='Adicionar Pagamento'
											type={'success'}
											icon={'fa fa-plus'}
											event={() => {
												this.props.setModoTelaPagamento('cadastro', {});
												this.props.initFormPagamento({
													id_comanda: this.props.formularioValues.id
												});
											}} />
									</Grid>
								</Row>}

								{this.props.modoTelaPagamento == 'cadastro' ? (
									<FormPagamento onSubmit={this.props.salvarPagamento} />
								) : (								
									<Table responsive>
										<THead>
											<Tr>
												<Th>Descrição</Th>
												<Th alignRight>Valor</Th>
												<Th></Th>
											</Tr>
										</THead>
										<TBody>
											{(this.props.listaPagamentos || []).map(item => {
												return (
													<Tr key={item.id}>
														<Td>{item.descricao}</Td>
														<Td alignRight>{FormatUtils.formatarValorTela(item.valor, 2)}</Td>
														<Td alignRight minWidth={100}>
															<ButtonTable
																type={'danger'}
																icon={'fas fa-trash-alt'}
																visible={!readOnly}
																event={() => {
																	this.props.excluirPagamento(item);
																}} />
														</Td>
													</Tr>
												);
											})}
											<Tr>
												<Th style={{ fontSize: 14 }}>Produtos</Th>
												<Th alignRight style={{ fontSize: 14 }}>{FormatUtils.formatarValorTela(totalItem, 2)}</Th>
												<Th></Th>
											</Tr>
											<Tr>
												<Th style={{ fontSize: 18 }}>Pago</Th>
												<Th alignRight style={{ fontSize: 18 }}>{FormatUtils.formatarValorTela(totalPagamento, 2)}</Th>
												<Th></Th>
											</Tr>
											<Tr>
												<Th style={{ fontSize: 18 }}>À Receber</Th>
												<Th alignRight style={{ fontSize: 18 }}>{FormatUtils.formatarValorTela(totalItem - totalPagamento, 2)}</Th>
												<Th></Th>
											</Tr>
										</TBody>
									</Table>
								)}
							</Grid>
						</Row>
					</ContentCardBody>
				) : null}			
			</ContentCard>
        )
    }


}

ComandaForm = reduxForm({form: 'comandaForm', destroyOnUnmount: false})(ComandaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('comandaForm')(state),
	registro: state.comanda.registro,
	listaItens: state.comanda.listaItens,
	listaPagamentos: state.comanda.listaPagamentos,
	listaUsuario: state.comanda.listaUsuario,
	modoTelaItem: state.comanda.modoTelaItem,
	modoTelaPagamento: state.comanda.modoTelaPagamento
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar,
	setModoTelaItem, initFormItem, salvarItem, excluirItem, getListaItens,
	setModoTelaPagamento, initFormPagamento, salvarPagamento, excluirPagamento, getListaPagamentos
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ComandaForm);
