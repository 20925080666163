import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';

import {
	setModoTela, initForm, getListaHorariosAgendados
} from './dashboardJogadorActions';

class DashboardAgendaForm extends Component {

	state = {
		horariosSelecionados: []
	}

	componentDidMount() {

		const fileSelector = document.createElement('input');
		fileSelector.setAttribute('type', 'file');
		fileSelector.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;

			if (file) {

				if (!file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas imagens (.PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					this.props.initForm({
						...this.props.formularioValues,
						imagem_capa_base64: reader.result
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelector = fileSelector;

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<Form event={this.props.handleSubmit}>
				{this.props.excluir ? this.montarExcluir() : this.montarAgendamento()}
			</Form>
        )
    }

	montarAgendamento() {

		if (this.props.formularioValues.aula && !this.props.formularioValues.professorSelecionado) {
			return this.selecionarProfessor();
		} else {
			return this.selecionarQuadra();
		}

	}

	montarExcluir() {

		let permiteRemover = (new Date (`${this.props.formularioValues.data_agenda.split('T')[0]} ${this.props.formularioValues.horarios[0]}`).getTime() - new Date().getTime() > 28800000);

		return (
			<Form event={this.props.handleSubmit}>

				<ContentCard>
					<ContentCardBody>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: 300, padding: 20 }}>
							<h4 style={{ color: '#415a77' }}>{this.props.formularioValues.nome_quadra}</h4>
							<h5 style={{ color: '#415a77' }}>{this.props.formularioValues.nome_quadra.includes('Churrasqueira') ? 'Churrasqueira' : this.props.formularioValues.nome_esporte}</h5>
							<h5 style={{ color: '#415a77' }}>{this.props.formularioValues.aula ? 'Aula' : 'Jogo'}{this.props.formularioValues.aula ? ' com ' : ''}{this.props.formularioValues.nome_professor}</h5>
							<h6 style={{ color: '#415a77' }}>{DateFormat.formatarDataSqlParaTela(this.props.formularioValues.data_agenda)} - {(this.props.formularioValues.horarios || []).join(', ')}</h6>
							<h5 style={{ color: '#415a77' }}>Valor: R$ {parseFloat(this.props.formularioValues.total || 0).toFixed(2).replace('.', ',')}</h5>
						</div>
						{this.props.excluir && this.props.formularioValues.fixo ? (
							<div className='row' style={{ justifyContent: 'center', padding: 10, marginBottom: 20 }}>
								<Grid cols='12 6 4 3'>
									<button
										type='button'
										class={`btn btn-block btn${this.props.formularioValues.todos ? '' : '-outline'}-secondary btn-lg`}
										style={{ fontWeight: 'bold' }}
										onClick={() => {
											this.props.initForm({
												...this.props.formularioValues,
												todos: this.props.formularioValues.todos ? false : true
											});
										}}>
											Remover este e os próximos agendados
									</button>
								</Grid>
							</div>
						) : null}
						{!permiteRemover ? (
							<div className='row' style={{ justifyContent: 'center', padding: 10, marginBottom: 20 }}>
								<h6 style={{ color: '#f00' }}>
									Só é possivel cancelar com até 12 horas de antecedência
								</h6>
							</div>
						) : null}
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								<Button
									text='Remover'
									disabled={!permiteRemover}
									submit type={'danger'}
									icon={'fa fa-trash'} />
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</ContentCard>

			</Form>
		);

	}

	selecionarProfessor() {

		return (
			<ContentCard>
				<ContentCardHeader>
					<Grid cols='12 12 12 12'>
						<h4 style={{ textAlign: 'center', margin: 20 }}>Escolha o Professor</h4>
					</Grid>
				</ContentCardHeader>
				<ContentCardBody>
					<div className='row' style={{ justifyContent: 'center', padding: 40 }}>
						{this.props.listaProfessor.map(item => (
							<Grid cols='12 6 4 3' key={item.id}>
								<button
									type='button'
									class='btn btn-block btn-outline-secondary btn-lg'
									style={{ fontWeight: 'bold' }}
									onClick={() => {
										this.props.initForm({
											...this.props.formularioValues,
											id_professor: item.id,
											professorSelecionado: true
										});
									}}>
										<img src={item.imagem_icone ? `https://api-golden.infinitum-ti.com/${item.imagem_icone}` : null} style={{ height: 32, marginRight: 4 }} />
										{item.valor}
								</button>
							</Grid>
						))}
						<Grid cols='12 6 4 3'>
							<button
								type='button'
								class='btn btn-block btn-outline-secondary btn-lg'
								style={{ fontWeight: 'bold' }}
								onClick={() => {
									this.props.initForm({
										...this.props.formularioValues,
										professorSelecionado: true
									});
								}}>
									Não selecionar professor
							</button>
						</Grid>
					</div>
				</ContentCardBody>
				<ContentCardFooter>
					<Row alignCenter>
						<Grid cols='12 6 4 3'>
							<Button
								text='Voltar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.setModoTela('lista')} />
						</Grid>
					</Row>
				</ContentCardFooter>
			</ContentCard>
		);

	}

	selecionarQuadra() {

		let diaSemana = new Date(this.props.formularioValues.data_agenda).getDay();
		let diasSemana = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'];

		let tipoAgendamentoSelecionado = !this.props.formularioValues.aula ? 1 : 2;

		//Busca a data limite para agendamento
		let ultimaDataAberta = new Date();
		ultimaDataAberta.setDate(ultimaDataAberta.getDate() + 60);
		ultimaDataAberta = `${ultimaDataAberta.getFullYear()}/${ultimaDataAberta.getMonth() < 9 ? '0' : ''}${ultimaDataAberta.getMonth() + 1}/${ultimaDataAberta.getDate() < 10 ? '0' : ''}${ultimaDataAberta.getDate()}`

		return (
			<>
				<div className='row' style={{ justifyContent: 'center' }}>
					<div class='btn-group'>
						<button type='button' title='Dia anterior' aria-pressed='false' class='fc-prev-button btn btn-primary'
							disabled={this.props.formularioValues.data_agenda == DateFormat.getDataAtual()}
							onClick={() => {
								let data = new Date(this.props.formularioValues.data_agenda);
								data.setDate(data.getDate() - 1);
								this.props.initForm({
									...this.props.formularioValues,
									data_agenda: `${data.getFullYear()}/${data.getMonth() < 9 ? '0' : ''}${data.getMonth() + 1}/${data.getDate() < 10 ? '0' : ''}${data.getDate()}`,
									horarios: [],
									preco: 0,
									total: 0
								});
								this.setState({ ...this.state, horariosSelecionados: [] });

								this.props.getListaHorariosAgendados(`${data.getFullYear()}/${data.getMonth() < 9 ? '0' : ''}${data.getMonth() + 1}/${data.getDate() < 10 ? '0' : ''}${data.getDate()}`);
							}}>
							<span class='fa fa-chevron-left'></span>
						</button>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingRight: 8, paddingLeft: 8, width: 120 }}>
							<h6>{DateFormat.formatarDataSqlParaTela(this.props.formularioValues.data_agenda)}</h6>
							<h7 style={{ marginBottom: 0 }}>{diasSemana[diaSemana]}</h7>
						</div>
						<button type='button' title='Próximo dia' aria-pressed='false' class='fc-next-button btn btn-primary'
						disabled={this.props.formularioValues.data_agenda == ultimaDataAberta}
							onClick={() => {
								let data = new Date(this.props.formularioValues.data_agenda);
								data.setDate(data.getDate() + 1);
								this.props.initForm({
									...this.props.formularioValues,
									data_agenda: `${data.getFullYear()}/${data.getMonth() < 9 ? '0' : ''}${data.getMonth() + 1}/${data.getDate() < 10 ? '0' : ''}${data.getDate()}`,
									horarios: [],
									preco: 0,
									total: 0
								});
								this.setState({ ...this.state, horariosSelecionados: [] });
								this.props.getListaHorariosAgendados(`${data.getFullYear()}/${data.getMonth() < 9 ? '0' : ''}${data.getMonth() + 1}/${data.getDate() < 10 ? '0' : ''}${data.getDate()}`);
							}}>
							<span class='fa fa-chevron-right'></span>
						</button>
					</div>
				</div>

				{this.props.listaQuadra.filter(quadra => {

					let tipoAgendaQuadra = 3;
					switch (diaSemana) {
						case 0:
							tipoAgendaQuadra = 3;
							break;
						case 1:
							tipoAgendaQuadra = quadra.horario_inicio && quadra.horario_inicio_aula ? 3 : (quadra.horario_inicio ? 1 : 2);
							break;
						case 2:
							tipoAgendaQuadra = quadra.horario_inicio && quadra.horario_inicio_aula ? 3 : (quadra.horario_inicio ? 1 : 2);
							break;
						case 3:
							tipoAgendaQuadra = quadra.horario_inicio && quadra.horario_inicio_aula ? 3 : (quadra.horario_inicio ? 1 : 2);
							break;
						case 4:
							tipoAgendaQuadra = quadra.horario_inicio && quadra.horario_inicio_aula ? 3 : (quadra.horario_inicio ? 1 : 2);
							break;
						case 5:
							tipoAgendaQuadra = quadra.horario_inicio && quadra.horario_inicio_aula ? 3 : (quadra.horario_inicio ? 1 : 2);
							break;
						case 6:
							tipoAgendaQuadra = 3;
							break;
						default:

					};

					return !quadra.bloqueado
						 && quadra.id_esporte == this.props.formularioValues.id_esporte
						 && (!this.props.formularioValues.id_quadra || quadra.id == this.props.formularioValues.id_quadra)
						 && (tipoAgendaQuadra == 3 || tipoAgendaQuadra == tipoAgendamentoSelecionado);
				 }).map(quadra => {

					//Monta a lista de horários conforme o dia da semana
					let horarioInicio = diaSemana == 0 ? quadra.horario_domingo_inicio : (diaSemana == 6 ? quadra.horario_sabado_inicio : (this.props.formularioValues.aula ? quadra.horario_inicio_aula : quadra.horario_inicio));
					let horarioFim = diaSemana == 0 ? quadra.horario_domingo_fim : (diaSemana == 6 ? quadra.horario_sabado_fim : (this.props.formularioValues.aula ? quadra.horario_fim_aula : quadra.horario_fim));

					let horas = [];
					//Bloqueia para o torneio
					if (horarioFim && this.props.formularioValues.data_agenda) {
						if ((this.props.formularioValues.data_agenda == '2023/06/15' && quadra.id != 3 && quadra.id != 5) || this.props.formularioValues.data_agenda == '2023/06/16') {
							if (horarioInicio > '16:30') {
								horarioInicio = null;
							}
							horarioFim = '16:30';
						} else if (this.props.formularioValues.data_agenda == '2023/06/17' || this.props.formularioValues.data_agenda == '2023/06/18') {
							horarioInicio = null;
							horarioFim = null;
						}
					}

					//Torneio
					if (quadra.id == 2 || quadra.id == 3 || quadra.id == 4 || quadra.id == 5 || quadra.id == 12 || quadra.id == 13 || quadra.id == 14) {
						if (this.props.formularioValues.data_agenda == '2024/03/16' || this.props.formularioValues.data_agenda == '2024/03/17') {
							horarioInicio = null;
							horarioFim = null;
						}
					}

					/*
						2 - BIG
						3 - TaubesCar
						4 - L4 Construtora
						5 - COI
						12 - HD
						13 - Pepsi Black
						14 - Corujão
					*/
					//Treinamento - libera as quadras pra jogo
					if ((this.props.formularioValues.data_agenda == '2023/09/06' 
						|| this.props.formularioValues.data_agenda == '2023/09/07' || this.props.formularioValues.data_agenda == '2023/09/08'
						|| this.props.formularioValues.data_agenda == '2023/09/11' || this.props.formularioValues.data_agenda == '2023/09/12'
						|| this.props.formularioValues.data_agenda == '2023/09/13' || this.props.formularioValues.data_agenda == '2023/09/14'
						|| this.props.formularioValues.data_agenda == '2023/11/02' || this.props.formularioValues.data_agenda == '2024/03/29')
						&& !this.props.formularioValues.aula && (quadra.id == 2 || quadra.id == 3 || quadra.id == 5 || quadra.id == 14)) {

						if (this.props.formularioValues.data_agenda == '2023/09/06') {
							horarioInicio = '12:00';
						} else {
							horarioInicio = '06:00';
						}
					}

					if ((this.props.formularioValues.data_agenda == '2023/11/02' || this.props.formularioValues.data_agenda == '2023/11/15')
						&& !this.props.formularioValues.aula && (quadra.id == 5)) {
						horarioInicio = '06:00';
					}

					if ((this.props.formularioValues.data_agenda == '2023/11/02' || this.props.formularioValues.data_agenda == '2023/11/15')
						&& !this.props.formularioValues.aula && (quadra.id == 14)) {
						horarioInicio = '06:00';
					}

					//Bloqueia para Natal e Ano Novo
					if (this.props.formularioValues.data_agenda == '2022/12/24'	|| this.props.formularioValues.data_agenda == '2022/12/31') {
						horarioFim = '12:30';
					}
					if (this.props.formularioValues.data_agenda == '2022/12/25'	|| this.props.formularioValues.data_agenda == '2023/01/01') {
						horarioInicio = null;
						horarioFim = null;
					}

					//Torneio
					if (quadra.nome != 'Churrasqueira 1' && quadra.nome != 'Churrasqueira 2') {
						if (this.props.formularioValues.data_agenda == '2022/10/22' || this.props.formularioValues.data_agenda == '2022/10/23') {
							horarioInicio = null;
							horarioFim = null;
						}
						if (this.props.formularioValues.data_agenda == '2022/10/20' || this.props.formularioValues.data_agenda == '2022/10/21') {
							horarioInicio = horarioInicio == '18:00' ? null : horarioInicio;
							horarioFim = horarioFim == '23:30' ? '17:30' : horarioFim;
						}
					}

					//Se tem Horário de Início e de Fim monta o quadr de horários
					if (horarioInicio && horarioFim) {
						let data = new Date();
						data.setHours(horarioInicio.split(':')[0]);
						data.setMinutes(horarioInicio.split(':')[1]);

						let horaGrade = `${data.getHours() < 10 ? '0' : ''}${data.getHours()}:${data.getMinutes() < 10 ? '0' : ''}${data.getMinutes()}`;

						//Verifica se já existe agendamento para o horário
						let agendados = this.props.listaHorariosAgendados.filter(agendado => agendado.id_quadra == quadra.id)[0] ? this.props.listaHorariosAgendados.filter(agendado => agendado.id_quadra == quadra.id)[0].horarios || [] : [];

						// //Libera as quadras 1 e 4 para JOGO as 06:00 e 06:30
						// if (!this.props.formularioValues.aula && horarioInicio == '18:00' && (quadra.id == '5' || quadra.id == '3')) {
						// 	horas.push({ hora: '06:00', bloqueado: agendados.includes('06:00') });
						// 	horas.push({ hora: '06:30', bloqueado: agendados.includes('06:30') });
						// }

						horas.push({ hora: horaGrade, bloqueado: agendados.includes(horaGrade) });

						while ((data.getHours() * 60) + data.getMinutes() < (parseInt(horarioFim.split(':')[0]) * 60) + parseInt(horarioFim.split(':')[1])) {
							data.setMinutes(data.getMinutes() + quadra.horario_unitario);
							//Verifica se já existe agendamento para o horário
							let horaGrade = `${data.getHours() < 10 ? '0' : ''}${data.getHours()}:${data.getMinutes() < 10 ? '0' : ''}${data.getMinutes()}`;
							horas.push({ hora: horaGrade, bloqueado: agendados.includes(horaGrade) });
						}
					}

					if (this.props.aguardando) {
						return null;
					}

					return (
						<ContentCard key={quadra.id}>
							<ContentCardHeader>
								<div className='row' style={{ justifyContent: 'center' }}>
									{/*<img src={quadra.imagem_capa ? `https://api-golden.infinitum-ti.com/${quadra.imagem_capa}` : null} style={{ height: 150 }} />*/}
									<h4 style={{ textAlign: 'center', padding: 4 }}>{quadra.nome} - {quadra.descricao}</h4>
								</div>
								<div className='row' style={{ justifyContent: 'center' }}>
									<h5 style={{ textAlign: 'center', padding: 4 }}>R$ {FormatUtils.formatarValorTela(this.props.formularioValues.total, 2)}</h5>
								</div>
								{this.props.formularioValues.aula ? (
									<div className='row' style={{ justifyContent: 'center' }}>
										<p style={{ textAlign: 'center', padding: 4 }}>{this.props.formularioValues.id_professor == 1 ? 'Valor da Aula Individual: R$ 120,00 | Dupla: R$ 80,00 | Trio: R$ 65,00' : 'Valor da Aula Individual: R$ 100,00 | Dupla: R$ 70,00 | Trio: R$ 60,00'}</p>
									</div>
								) : null}
							</ContentCardHeader>
							<ContentCardBody>
								<div className='row' style={{ justifyContent: 'center', marginRight: 4, padding: 40 }}>
									{horas.map((horario, i) => {

										return (
											<div key={`${i}-${this.state.horariosSelecionados}`} class='col-3 col-xs-3 col-sm-3 col-md-2 col-lg-1' style={{ margin: 0, padding: 4 }}>
												<button
													type='button'
													class={`btn btn-block btn${this.state.horariosSelecionados && this.state.horariosSelecionados.filter(horarioSelecionado => horarioSelecionado == horario.hora)[0] ? '' : '-outline'}-secondary btn-lg`}
													style={{ fontWeight: 'bold', backgroundColor: horario.bloqueado ? '#90a2ae' : '' }}
													disabled={horario.bloqueado}
													onClick={horario.bloqueado ? () => {} : () => {

														let naoAgendado = false;

														let horariosSelecionados = this.state.horariosSelecionados.map(horarioAux => horarioAux) || [];
														//Se já tem o horário => remove, senão => adiciona
														if (horariosSelecionados.filter(horarioSelecionado => horarioSelecionado == horario.hora)[0]) {
															horariosSelecionados = horariosSelecionados.filter(horarioSelecionado => horarioSelecionado != horario.hora);
															if (horariosSelecionados.length == 1) {
																horariosSelecionados = [];
															}
														} else {
															horariosSelecionados.push(horario.hora);
														}

														let quantidade_minima = this.props.formularioValues.aula ? quadra.quantidade_minima_aula : quadra.quantidade_minima_jogo;

														//Verifica a quantidade mínima de horários
														if (horariosSelecionados.length > 0 && horariosSelecionados.length < quantidade_minima) {
															for (var j = i+1; j < i + quantidade_minima; j++) {
																//Se ainda não tem => adiciona
																if (horas[j] && !horariosSelecionados.filter(horarioSelecionado => horarioSelecionado == horas[j].hora)[0]) {
																	if (!naoAgendado && horas[j].bloqueado) {
																		naoAgendado = true;
																	}
																	if (!horas[j].bloqueado) {
																		horariosSelecionados.push(horas[j].hora);
																	}
																}
															}
														}
														horariosSelecionados.sort();

														//Remove duplicados

														if (horariosSelecionados[0]) {

															let primeiroHorario = (parseInt(horariosSelecionados[0].split(':')[0]) * 60) + parseInt(horariosSelecionados[0].split(':')[1]);
															let ultimoHorario = (parseInt(horariosSelecionados[horariosSelecionados.length - 1].split(':')[0]) * 60) + parseInt(horariosSelecionados[horariosSelecionados.length - 1].split(':')[1]);

															horas.forEach(horarioAux => {

																let timeAux = (parseInt(horarioAux.hora.split(':')[0]) * 60) + parseInt(horarioAux.hora.split(':')[1]);
																if (timeAux >= primeiroHorario && timeAux <= ultimoHorario) {
																	if (!horariosSelecionados.filter(horarioSelecionado => horarioSelecionado == ultimoHorario)[0]) {
																		if (!naoAgendado && horarioAux.bloqueado) {
																			naoAgendado = true;
																		}
																		if (!horarioAux.bloqueado) {
																			horariosSelecionados.push(horarioAux.hora);
																		}
																	}
																}
															});
														}

														//Remove duplicados
														horariosSelecionados = horariosSelecionados.filter((item, pos, self) => self.indexOf(item) == pos);

														horariosSelecionados.sort();

														//Se os horários não são sequenciais ou possui apenas um horário vago não permite marcar
														if (!naoAgendado && (horariosSelecionados.length == 0 || horariosSelecionados.length >= quantidade_minima)) {

															this.setState({ ...this.state, horariosSelecionados: horariosSelecionados });

															this.props.initForm({
																...this.props.formularioValues,
																horarios: horariosSelecionados,
																preco: quadra.preco_unitario,
																total: quadra.preco_unitario * horariosSelecionados.length
															});
														}
													}}>
														{horario.hora}
												</button>
											</div>
										);
									})}
								</div>

								<div className='row' style={{ justifyContent: 'center', padding: 10, marginBottom: 20 }}>
									{((this.props.formularioValues.data_agenda == '2022/09/07' || this.props.formularioValues.data_agenda == '2022/09/20')
										&& !this.props.formularioValues.aula && (quadra.id == 3 || quadra.id == 5)) ? (
										null
									) : (
									<Grid cols='12 6 4 3'>
										<button
											type='button'
											class={`btn btn-block btn${this.props.formularioValues.fixo ? '' : '-outline'}-secondary btn-lg`}
											style={{ fontWeight: 'bold' }}
											onClick={() => {
												this.props.initForm({
													...this.props.formularioValues,
													fixo: this.props.formularioValues.fixo ? false : true
												});
											}}>
												Marcar Horário Fixo
										</button>
									</Grid>
								)}
								</div>
							</ContentCardBody>
							<ContentCardFooter>
								<Row alignCenter>
									<Grid cols='8 8 4 3'>
										<Button
											text='Agendar'
											submit
											type={'success'}
											icon={'fa fa-check'} />
									</Grid>
									<Grid cols='4 4 3 2'>
										<Button
											text='Voltar'
											type={'warning'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.setModoTela('lista')} />
									</Grid>
								</Row>
							</ContentCardFooter>
						</ContentCard>
					);
				})}
			</>
		);

	}

	selecionarAgenda() {

		return (
			<>
			</>
		);

	}

}

DashboardAgendaForm = reduxForm({form: 'agendaJogadorForm', destroyOnUnmount: false})(DashboardAgendaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('agendaJogadorForm')(state),
	registro: state.dashboardJogador.registro,
	aguardando: state.dashboardJogador.aguardando,
	listaEsporte: state.dashboardJogador.listaEsporte,
	listaProfessor: state.dashboardJogador.listaProfessor,
	listaHoras: state.dashboardJogador.listaHoras,
	listaQuadra: state.dashboardJogador.listaQuadra,
	listaUsuario: state.dashboardJogador.listaUsuario,
	listaHorariosAgendados: state.dashboardJogador.listaHorariosAgendados
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, getListaHorariosAgendados
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DashboardAgendaForm);
